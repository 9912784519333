import routerjs from "./routers.js"
import Home from '../views/Home'
import { getLargeCategory } from "@/api/chart/index.js"
import jsCookie from 'js-cookie';
const childrenlist = [
    // {
    //     path: '/echarshtlm',
    //     name: 'echarshtlm',
    //     // redirect: '/echarshtlm',
    //     // alias: '/echarshtlm',
    //     meta: {
    //       metadisabled: true,
    //       title: '通用图表展示页面'
    //     },
    //     component: routerjs.echarshtlm
    // },
    {
        path: '/databaseLink',
        name: 'databaseLink',
        meta: {
            metadisabled: true,
            title: '数据库链接'
        },
        component: routerjs.databaseLink
    },
    {
        path: '/dataSet',
        name: 'dataSet',
        meta: {
            metadisabled: true,
            title: '数据集',
            keepAlive:true
        },
        component: routerjs.dataSet
    },
    {
        path: '/setDataSet',
        name: 'setDataSet',
        meta: {
            metadisabled: true,
            title: '新增数据集'
        },
        component: routerjs.setDataSet
    },
    {
        path: '/addDataSet/:id',
        name: 'addDataSet',
        meta: {
            metadisabled: true,
            title: '编辑数据集'
        },
        component: routerjs.addDataSet
    },
    {
        path: '/chart',
        name: 'chart',
        meta: {
            metadisabled: true,
            title: '图表设置'
        },
        component: routerjs.chart
    },
    {
        path: '/setTable/:id',
        name: 'setTable',
        meta: {
            metadisabled: true,
            title: '新增图表'
        },
        component: routerjs.setTable
    },
    {
        path: '/addTable/:id/:dataId/:name/:heightList',
        name: 'addTable',
        meta: {
            metadisabled: true,
            title: '编辑图表'
        },
        component: routerjs.addTable
    },
]
childrenlist.push(
    {
        path: '/echarshtlm',
        name: 'echarshtlm',
        meta: {
          metadisabled: true,
          title: '首页'
        },
        component: routerjs.echarshtlm
    },
)

let baseRoutes = [
    {
      path: '/',
      name: 'Home',
      component: Home,
      redirect: '/echarshtlm',
      meta: {
        metadisabled: true
      },
      children: childrenlist
    },
    {
      path: '/Login',
      name: 'Login',
      component: () => import('../views/login/index.vue')
    },
    {
        path: '/moveCharts/:id/:name',
        name: 'moveCharts',
        meta: {
            metadisabled: true,
            title: '图表展示'
        },
        component: () => import("@/views/moveCharts/index.vue")
    },
]
// let Pm = new Promise(function (resolve, reject) {
//     let baseRoutes = []
//     setTimeout(() => {
//         baseRoutes = [
//             {
//               path: '/',
//               name: 'Home',
//               component: Home,
//               redirect: 'echarshtlm',
//               meta: {
//                 metadisabled: true
//               },
//               children: childrenlist
//             },
//             {
//               path: '/Login',
//               name: 'Login',
//               component: () => import('../views/login/index.vue')
//             },
//         ]
//         resolve(baseRoutes)
//     }, 1000);
// })
// export { Pm }
export default baseRoutes