<template>
  <div style="height: 100%;">
    <!-- 数据集界面 -->
    <div v-if="fieldPropConfigs && fieldPropConfigs.length > 0 " :style="{ height: this.subTableName.length ? '110%' : '146%' }">
      <el-table border :data="tableData" ref="tableRef" show-summary style="width: 100%" :summary-method="getSummaries"
        :height="this.subTableName.length ? '80%' : '90%'"  :row-class-name="tableRowClassName" :cell-class-name="tableCellClassName"
        @sort-change="sortChange" @row-click="openDetails" @cell-click="cellClick">
        <el-table-column label="序号" type="index" width="80" align="center" fixed="left">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column v-for="(item, index) in fieldPropConfigs" :key="index" show-overflow-tooltip
          sortable="custom" :prop="item.fname" :label="item.showName" :width="item.colWidth ? item.colWidth : '150'"
          :align="item.ftype == '1' ? 'right' : 'left'">
          <template slot-scope="scope">
            <span v-if="scope.row.backgSpan" class="backgSpanvalue">{{scope.row[item.fname]}}</span>
            <span v-else-if="scope.row.index === rowIndex && scope.column.index === columnIndex && scope.column.label == '备注'">
              <el-input v-model="scope.row[item.fname]" type="textarea" placeholder="请输入备注" size="mini" @blur="inputblur(scope.row)"/>
            </span>
            <span v-else>{{ scope.row[item.fname] }}</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination v-if="fieldPropConfigs && fieldPropConfigs.length > 0" background
        style="margin-top: 20px" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[20, 50, 100, 200]"
        :current-page="pageObj.pageNum" :page-size="pageObj.pageSize" :total="pageObj.totalRecord"
        @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>

    <!-- 子表 -->
    <div v-if="subTableName.length">
      <el-tabs @tab-click="handleClick" type="border-card" v-model="activeName">
        <el-tab-pane v-for="(item, index) in subTableName" :key="index" :label="item.dsName" :name="item.id" style="height: 100%">
          <el-table border :data="subTableData" v-loading="loading" :summary-method="getSummariess" show-summary>
            <el-table-column label="序号" type="index" width="80" align="center" fixed="left"></el-table-column>
            <el-table-column v-for="(items, index) in subTableHeader" :key="index" :label="items.fname"
              :prop="items.fname" show-overflow-tooltip></el-table-column>
          </el-table>
          <el-pagination v-if="subTableData && subTableData.length > 0" background style="margin-top: 20px"
            layout="total, sizes, prev, pager, next, jumper" :page-sizes="[20, 50, 100, 200]"
            :current-page="sublist.pageNum" :page-size="sublist.pageSize" :total="sublist.totalRecord"
            @size-change="handleSizeChangess" @current-change="handleCurrentChangess" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { getChartsList } from "@/api/moveCharts/moveCharts.js";
import { getChartTablepost } from "@/api/chart/index.js";
import { detailDeptree } from "@/api/dataSet/index.js";

export default {
  name: "chart-table",
  components: {},
  props: {
    Objtable: {
      type: Object,
      required: {},
    },
  },
  watch: {
    Objtable(val) {
      if (val.id) { 
        this.init(val.id)
      }
    },
  },
  data() {
    return {
      fieldPropConfigs: [], // 表头数据
      tableData: [], //表格数据
      mainTableIndex: {}, //主表下标为0的数据
      sumsadd: {}, // 存储合计数据
      tableDataParam: {},
      sortName: "", // 哪个字段进行排序
      tableAsc: false, // 排序字段向上还是向下
      pageObj: { pageNum: 1, pageSize: 20, totalRecord: 0 },


      //子表的变量
      sublist: { pageNum: 1, pageSize: 20, totalRecord: 0 },
      subTableName: [], //tabs标签页
      subTableHeader: [], //子表表头
      subTableData: [], //子表数据
      activeName: "", //子表ID
      subTableid: "", //主表ID
      ridObj: {},
      currentRowOfMainTable: "", //判断有没点击主表行
      mainTableIndex: {}, //主表下标为0的数据
      tabsIndex: 0, //标签页下标
      loading: true,
      remarks: [], //备注
      tabClickIndex: "",
      tabClickLabel: "",
      rowIndex: -1, //行索引
      columnIndex: -1, //列索引
      remarksShow: false, //判断显示隐藏保存按钮
    };
  },
  created() {},
  mounted() {},
  methods: {
    async init(iteamId) {
      let response = await getChartsList({ catId: iteamId });
      if (this.$jsons.data.status) {
        // 将datasetId、filterPropList字段数据保存，给分页及排序使用
        this.tableDataParam = {
          datasetId: response[0].datasetId,
          filterPropList: response[0].filterPropList,
        };
        this.getTableData(response[0]);
        this.secondaryDataSet(response[0].datasetId);
      }
    },

    /**主表方法----------------------------------------------------------------------------------------- */
    // 获取主表数据
    async getTableData(item) {
      let params = { id: item.datasetId, orderBy: this.sortName, asc: this.tableAsc, ...this.pageObj };
      if (!item.filterPropList) item.filterPropList = [];
      console.log(params);

      // 主表数据接口
      let response = await getChartTablepost(params, item.filterPropList);
      if (this.$jsons.data.status) {
        // 将主表中的第一条数据存储起来给子表使用
        if (response.result[0] !== undefined) {
          this.mainTableIndex = JSON.parse(JSON.stringify(response.result[0]));
        }

        // 将合计数据存储起来给合计方法使用
        this.sumsadd = response.sums;

        // 判断isWhereField 是否显示，显示代表添加备注列
        this.fieldPropConfigs =  response.fieldPropConfigs.filter(
          (f) => f?.isWhereField
        );
        this.remarksShow = response.fieldPropConfigs.filter((item) => {
          return item.showName == "备注";
        });

        // 过滤出类型为1或者3的数据
        const numberadd = response.fieldPropConfigs.filter((item) => {
          return (item.ftype === 1 || item.ftype === 3) && item.decimalDigit !== null
        })

        let newSubSums = this.subSumsFunction(response.subSums, response.result);
        let _this = this; // 需要将外面的指向赋值给_this
        this.tableData = newSubSums.map(function (item) {
          for (let i in item) {
            for (let j = 0; j < numberadd.length; j++) {
              if (i == numberadd[j].fname) {
                // 这里的this指向的是内部的function
                item[i] = _this.toThousands(item[i], numberadd[j].decimalDigit)
              }
            }
          }
          return item;
        });
        
        this.pageObj.totalRecord = response.totalRecord;
        this.pageObj.pageNum = response.pageNum;
        this.pageObj.pageSize = response.pageSize;
      }
    },

    // 行的 className 的回调方法，也可以使用字符串为所有行设置一个固定的 className。
    tableRowClassName({ row, rowIndex }) {
      //把每一行的索引加到行数据中
      row.index = rowIndex;
    },
    //单元格的 className 的回调方法，也可以使用字符串为所有单元格设置一个固定的 className。
    tableCellClassName({ column, columnIndex }) {
      //把每一列的索引加到列数据中
      column.index = columnIndex;
    },
    // 主表排序,当表格的排序条件发生变化的时候会触发该事件
    sortChange(columns) {
      if (columns.order == "ascending") {
        this.tableAsc = true;
        this.sortName = columns.prop;
      } else if (columns.order == "descending") {
        this.tableAsc = false;
        this.sortName = columns.prop;
      } else {
        this.tableAsc = null;
        this.sortName = "";
      }
      this.getTableData(this.tableDataParam);
    },
    //当某一行被点击时会触发该事件
    openDetails(row, column) {
      this.currentRowOfMainTable = row;
      let baseArr = [],
        newArr = [];
      if (this.subTableName.length) {
        this.subTableName.forEach((t) => baseArr.push(t?.referCondition));
        this.activeName = this.subTableName[0].id;
      }
      newArr = baseArr.filter((str) => str.includes("@"));
      newArr = newArr.map((item) => {
        return (item = item.split("@")[1]);
      });
      newArr.forEach((str) =>
        row?.[str] ? (this.ridObj[str] = row[str]) : null
      );
      if (this.subTableName.length) this.handleClick({ index: this.tabsIndex });
    },
    //当某个单元格被点击时会触发该事件
    cellClick(row, column, cell, event) {
      if (column.label == "备注") {
        this.rowIndex = row.index;
        this.columnIndex = column.index;
      }
    },
    //输入框失去焦点事件（初始化中间变量）
    inputblur(scope) {
      this.remarks.push(scope);
      this.rowIndex = -1;
      this.columnIndex = -1;
    },
    // 主表合计事件,自定义的合计计算方法
    getSummaries(param) {
      return this.totalEncapsulation(
        param,
        this.fieldPropConfigs,
        this.sumsadd
      );
    },
    /**------------------------------------------------------------------------------------------------ */


    /**子表标签页及子表方法----------------------------------------------------------------------------- */

    //子表标签页事件
    handleClick(tab, event) {
      this.tabsIndex = tab.index;
      this.activeName = this.subTableName[this.tabsIndex].id;
      let obj = {};
      obj.rid = Object.keys(this.ridObj)[this.tabsIndex];
      obj.ridvalue = Object.values(this.ridObj)[this.tabsIndex];
      let paramss = {
        id: this.subTableName[this.tabsIndex].id,
        pageNum: this.sublist.pageNum,
        pageSize: this.sublist.pageSize,
      };
      if (JSON.stringify(this.ridObj) !== "{}")
        paramss = Object.assign(paramss, obj);
      // 从表数据
      const successFN = (yeap) => {
        this.loading = false;
        this.sublist.pageNum = yeap.pageNum;
        this.sublist.pageSize = yeap.pageSize;
        this.sublist.totalRecord = yeap.totalRecord;
        this.subTableHeader = yeap.fieldPropConfigs;
        this.subTableData = yeap.result;
        this.sumsadds = yeap.sums;
        this.$forceUpdate();
      };
      if (!this.tableDataParam?.filterPropList?.length) {
        getChartTablepost(paramss, this.subTableHeader).then((res) =>
          successFN(res)
        );
      } else {
        getChartTablepost(paramss, this.tableDataParam.filterPropList).then((res) =>
          successFN(res)
        );
      }
    },

    //展示页面,子表数据
    secondaryDataSet(val) {
      setTimeout(async () => {
        this.subTableid = val;
        let response = await detailDeptree({ id: this.subTableid, ...this.sublist });
        if (this.$jsons.data.status) { 
          this.subTableName = response.children;
          if (this.subTableName.length > 0) {
            let cromTable = this.currentRowOfMainTable
            if (cromTable == "" || cromTable == null || cromTable == undefined) {
              let baseArr = [];
              let newArr = [];
              if (this.subTableName.length) {
                this.subTableName.forEach((t) => baseArr.push(t?.referCondition));
                this.activeName = this.subTableName[this.tabsIndex].id;
              }
              newArr = baseArr.filter((str) => str.includes("@"));
              newArr = newArr.map((item) => {
                return (item = item.split("@")[1]);
              });
              newArr.forEach((str) =>
                this.mainTableIndex?.[str]
                  ? (this.ridObj[str] = this.mainTableIndex[str])
                  : null
              );
              this.handleClick({ index: this.tabsIndex });
            }
          }
        }
      }, 1500);
    },
    // 子表合计事件
    getSummariess(param) {
      return this.totalEncapsulation(
        param,
        this.subTableHeader,
        this.sumsadds);
    },

    /**------------------------------------------------------------------------------------------------ */


    // 一页大小
    handleSizeChange(val) {
      this.pageObj.pageSize = val;
      this.getTableData(this.tableDataParam);
    },
    // 点击页码
    handleCurrentChange(val) {
      this.pageObj.pageNum = val;
      this.getTableData(this.tableDataParam);
    },
    // 合计一页大小
    handleSizeChangess(val) {
      this.sublist.pageSize = val;
      this.secondaryDataSet(this.subTableid);
    },
    // 合计页码
    handleCurrentChangess(val) {
      this.sublist.pageNum = val;
      this.secondaryDataSet(this.subTableid);
    },


    // 合计封装
    totalEncapsulation(params, headers, totals) {
      const { columns, data } = params;
      const sums = [];
      let isTotaladd = headers.filter(function(item) {
        return item.isTotal;
      });
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        for (let i = 0; i < isTotaladd.length; i++) {
          if (column.property === isTotaladd[i].fname) {
            sums[index] = this.toThousands(totals[column.property], 2);
          }
        }
      });
      return sums;
    },

    subSumsFunction(subSums, result) {
      if (subSums.length === 0) return result;
      const add = [];
      for (const subSum of subSums) {
        const subTotalGroup = subSum.subTotalGroup;
        const subTotalGroupKeys = Object.keys(subTotalGroup);
        const subTotalGrouplength = subTotalGroupKeys.length;

        const filteredResults = result.filter((r) => {
          let subTotalGroupcount = 0;
          for (const key of subTotalGroupKeys) {
            if (r[key] === subTotalGroup[key]) {
              subTotalGroupcount++;
            }
          }
          return subTotalGroupcount === subTotalGrouplength;
        });

        const data = { ...subSum.subTotal, backgSpan: true };
        filteredResults.push(data);
        add.push(filteredResults);
      }

      const arr = add.flat();
      return arr;
    },

    // 千分位转换事件
    toThousands(num, expressionDecimalDigit) {
      let newnum = num ?? "";
      if (expressionDecimalDigit && expressionDecimalDigit !== 0) {
        let [sInt, sFloat] = (Number.isInteger(newnum)
          ? `${newnum}`
          : Number(newnum)?.toFixed(expressionDecimalDigit) + ""
        ).split(".");
        sInt = sInt.replace(/\d(?=(\d{3})+$)/g, "$&,");
        return sFloat ? `${sInt}.${sFloat}` : `${sInt}`;
      } else {
        let [sInt, sFloat] = (Number.isInteger(newnum)
          ? `${newnum}`
          : newnum
        ).split(".");
        sInt = sInt.replace(/\d(?=(\d{3})+$)/g, "$&,");
        return sFloat ? `${sInt}.${sFloat}` : `${sInt}`;
      }
    }
  },
};
</script>

<style lang="less"></style>
