import Vue from 'vue';

import axios from 'axios';

import { Message } from 'element-ui';
import jsCookie from 'js-cookie';
// import { getToken } from "@/utils/auth";
import { cancelApi, myLoading } from '@/utils/myApi';
import store from "@/store"
import router from "@/router";

let Loading = new myLoading('#app');
let CancelApi = new cancelApi();
Vue.prototype.CancelApi = CancelApi;
let baseURL = process.env.VUE_APP_BASE_API;
baseURL = window.baseURL
console.error('baseURL', baseURL)
const server = axios.create({
	baseURL: baseURL,
	timeout: 50000,
});
// const noToken = ['/auth']
server.interceptors.request.use(
	config => {
		CancelApi.remove(config);
		CancelApi.add(config);
		// config.headers.Authorization = getToken() || "";
		// let reportFlag = window.opener['reportFlag']


		if ((!(window.name))) {
			// store.dispatch('LoginOut')
			// 如果不是嵌入 如果有登陆过清除权限

			store.commit('SET_ISID', '')
			jsCookie.remove("isId");
			jsCookie.remove("bsToken");

		}

		const isToken = (config.headers || {}).isToken === false
		if (jsCookie.get('token') && !isToken) {
			config.headers.Authorization = jsCookie.get('token') || '';
		}
		// config.headers.Authorization = jsCookie.get('token') || '';
		// if(noToken.indexOf(config.url)>-1){
		// 	config.headers.Authorization=''
		// }
		if (config.url === '/route/route') {
			Loading.start('正在初始化请稍等...');
		}
		return config;
	},
	err => {
		return Promise.reject(err);
	}
);

server.interceptors.response.use(
	response => {
		return new Promise((resolve, reject) => {
			Vue.prototype.$jsons = response;
			CancelApi.remove(response);
			Loading.end();
			if (
				(typeof response.data == 'string' || response.data instanceof Blob) &&
				response.status == 200
			) {
				resolve(response);
				return 
			}
			const res = response.data;
			if (!res.status) {
				if (res.code == 400 || res.code==403) {
					return res;
				}
				if (res.code == 401) {
					// token过期
					store.dispatch('LoginOut')
					router.replace("/login");
					// 刷新页面清空缓存
					setTimeout(() => {
						window.location.reload()
					}, 100);
					// window.location.origin
					// location.href = '/login';
				}
				Message.error(res.message)
				reject(res)
				return
			}
			resolve(res.data)
		})
	},
	err => {
		Loading.end();
		if (!axios.isCancel(err)) {
			Message.error({
				message: err.message,
				duration: 4000,
			});
		}
		let promise = new Promise(() => {
			setTimeout(() => {
				// promise.cancel();
			});
		});
		return promise;
	}
);

export default server;
