<template>
 <div class="">
    <el-divider v-if="FormInformation && FormInformation.length > 0" content-position="left">条件筛选</el-divider>
    <el-form v-if="FormInformation && FormInformation.length > 0" status-icon label-width="100px" class="demo-ruleForm">
        <el-form-item v-for="(item, index) in FormInformation" :key="index" :label="item.showName">
            <el-input v-if="item.inputType == 'string'" size="mini" v-model="item.value" :placeholder="'请输入' + item.showName"></el-input>
            <el-date-picker v-if="item.inputType == 'date'" size="mini" v-model="item.betweenValue" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="searchClick">搜索</el-button>
        </el-form-item>
    </el-form>
 </div>
</template>
<script>
import clone from "clone"
import moment from 'moment';
export default {
   name: 'Y-searchPcForm',
   components: {
   },
   props: {
     searchFormInformation: {
       type: Array,
       default: [],
     },
   },
   watch:{
        searchFormInformation: {
            handler(newName) {
                this.init()
            },
            immediate: true,
            deep: true
        }
   },
   data () {
     return {
        FormInformation: []
     }
   },
   created () {
       this.init()
   },
   mounted () {
   },
   methods: {
       init () {
           this.FormInformation = []
           let data = clone(this.searchFormInformation)
           for (let i = 0; i < data.length; i ++) {
               if (data[i].ftype == 2) {
                   data[i].inputType = "date"
               } else {
                   data[i].inputType = "string"
               }
           }
           this.FormInformation = data
       },
       searchClick () {
            let data = clone(this.FormInformation)
            for (let i = 0; i < data.length; i ++) {
                if (data[i].betweenValue !== null && data[i].betweenValue !== undefined) {
                    for (let j = 0; j < data[i].betweenValue.length; j ++) {
                        data[i].betweenValue[j] = moment(data[i].betweenValue[j]).format('YYYY-MM-DD')
                    }
                }
                delete data[i].inputType
            }
            this.$emit("searchClick", data)
       },
   },
 }
</script>

<style scoped lang='less'>
</style>
