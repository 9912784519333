export default {
  tags: [
    {
      fullPath: "/echarshtlm",
      hash: "",
      meta: { title: "图表", icon: null },
      name: "echarshtlm",
      params: {},
      path: "/echarshtlm",
      query: {},
      title: "图表",
    },
  ],
}