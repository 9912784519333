<!-- @author zhengjie -->
<template>
  <div class="icon-body">
    <el-input v-model="name" style="position: relative" clearable placeholder="请输入图标中文名称或英文名称" @clear="filterIcons"
      @input="filterIcons">
      <i slot="suffix" class="el-icon-search el-input__icon" />
    </el-input>
    <div class="content font-class">
      <el-tabs v-model="activeName">
        <el-tab-pane label="单色图标" name="first">
          <ul class="icon_lists dib-box">
            <li class="dib" v-for="(item, index) in iconList" :key="index" @click="selectedIcon(item.icon)">
              <span :class="'icon iconfont ' + item.icon"></span>
              <div class="name">{{ item.name }}</div>
              <div class="code-name">{{ item.icon }}</div>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane label="多色图标" name="second">
          <ul class="icon_lists dib-box">
            <li class="dib" v-for="(item, index) in colorIconList" :key="index" @click="selectedIcon(item.icon)">
              <svg class="icon svg-icon" aria-hidden="true">
                <use :xlink:href="'#' + item.icon"></use>
              </svg>
              <div class="name">{{ item.name }}</div>
              <div class="code-name">{{ item.icon }}</div>
            </li>
          </ul>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
// import icons from "./requireIcons";
import * as iconDatas from "./iconsData"

export default {
  name: "IconSelect",
  data() {
    return {
      name: "",
      activeName: "first",


      iconList: [],
      colorIconList: [],
    };
  },
  props: {
    //要回填的字段名

  },
  created() {
    for (let iconName of  Object.keys(iconDatas)) {  
     this[iconName]=iconDatas[iconName]  
}

    this.iconList = Object.assign(iconDatas.dataSourceIconList, []);
    this.colorIconList = Object.assign(iconDatas.dataSourceColorIconList, []);
  },
  methods: {
    filterIcons() {
      const fileds = ({
        'first': () => "iconList-dataSourceIconList",
        'default': () => "colorIconList-dataSourceColorIconList"
      }[this.activeName == 'first' ? this.activeName : 'default']()).split('-')
      this[fileds[0]] = this[fileds[1]]
      if (this.name) {
        this[fileds[0]] =this[fileds[1]].filter(item => item.name.match(new RegExp(this.name)) || item.icon == this.name)
      }

    },
    selectedIcon(name) {
      this.$emit("selected", { name: name });
      document.body.click();
    },
    reset() {
      this.name = "";
      this.iconList = Object.assign(iconDatas.dataSourceIconList, []);
      this.colorIconList = Object.assign(iconDatas.dataSourceColorIconList, []);
    },
  },
};
</script>

<style  scoped>
.icon-body {
  width: 550px;
  height: 400px;
  margin: 0 auto;
  overflow-y: auto;
}

#tabs {
  border-bottom: 1px solid #eee;
}

#tabs li {
  cursor: pointer;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 16px;
  border-bottom: 2px solid transparent;
  position: relative;
  z-index: 1;
  margin-bottom: -1px;
  color: #666;
}

#tabs .active {
  border-bottom-color: #f00;
  color: #222;
}

.tab-container .content {
  display: none;
}

.icon_lists {
  width: 100% !important;
  overflow: hidden;
  *zoom: 1;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.icon_lists li {
  width: 80px;
  margin-bottom: 10px;
  margin-right: 20px;
  text-align: center;
  list-style: none !important;
  cursor: default;
}

.icon_lists li .code-name {
  line-height: 1.2;
}

.icon_lists .icon {
  display: block;
  height: 40px;
  line-height: 40px;
  font-size: 30px;
  margin: 10px auto;
  color: #333;
  -webkit-transition: font-size 0.25s linear, width 0.25s linear;
  -moz-transition: font-size 0.25s linear, width 0.25s linear;
  transition: font-size 0.25s linear, width 0.25s linear;
}

.icon_lists .icon:hover {
  font-size: 40px;
}

.icon_lists .svg-icon {
  /* 通过设置 font-size 来改变图标大小 */
  width: 1em;
  /* 图标和文字相邻时，垂直对齐 */
  vertical-align: -0.15em;
  /* 通过设置 color 来改变 SVG 的颜色/fill */
  fill: currentColor;
  /* path 和 stroke 溢出 viewBox 部分在 IE 下会显示
      normalize.css 中也包含这行 */
  overflow: hidden;
}

.icon_lists li .name,
.icon_lists li .code-name {
  color: #666;
}
</style>
