<template>
  <el-popover placement="bottom-start" trigger="click" size="small">
    <selectPoper ref="iconSelect" @selected="selected" />
    <el-input
      slot="reference"
      v-model="selectedIcon"
      :placeholder="placeholder"
      :clearable="clearable"
      size="small"
      style="width: 180px"
    >
      <svg-icon
        v-if="selectedIcon"
        slot="prefix"
        :icon-class="selectedIcon"
        style="font-size: 20px; margin-top: 10px"
        class="el-input__icon"
      />
      <i v-else slot="prefix" class="el-icon-search el-input__icon" />
    </el-input>
  </el-popover>
</template>
<script>
import selectPoper from "./selectpop.vue";
export default {
  components: { selectPoper },

  name: "IconSelect",
  data() {
    return {
      selectedIcon: "", // 已选的icon
    };
  },
  props: {
    value: {
      require: true,
    },
    clearable: {
      default: false,
    },
    placeholder: {},
  },
  created() {},
  watch: {
    value: {
      handler(val) {
        if (val) {
          this.selectedIcon = val;
        } else {
          this.selectedIcon = "";
          this.$nextTick(() => {
            this.$refs.iconSelect.reset();
          });
        }
      },
      deep: true,
      immediate: true,
    },
    selectedIcon(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    selected({ name }) {
      this.selectedIcon = name;
      //  this.$emit('input',name)
    },
  },
};
</script>
