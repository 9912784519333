import { Message,Alert } from "element-ui";
import jsCookie from 'js-cookie';
import router from './router'
import { setSession } from '@/utils/methods';
import store from './store';

const whiteList = ['/login', '/auth-redirect', '/404', '/register'];

router.beforeEach((to, from, next) => {
const { name, fullPath, hash, meta, params, path, query } = to;
const obj = { name, fullPath, hash, meta, params, path, query };
  if(jsCookie.get('token')){

    if(window.name ){
      if(!jsCookie.get('bsToken') || ((window.name || window.name.token)!== jsCookie.get('bsToken') )){
        console.log("跳转来没登录过,但是有token,又或者跳转来的token不相同")
        // 是跳转过来的并且没有登陆过 
        store.dispatch('LoginOut')
          next({path:"/"})
          return
      }
  
    }

    // 有token
    if(path==='/login'){
      // 有登录的login

      next({path:"/"})
    }else{
      if(store.state.user.permissions.length===0){
        console.log('没有权限 需要重新获取')
        store.dispatch('getPermission').then(res=>{
          setSession('tagStep', from.fullPath);
          store.dispatch('global/TAG_ADD', obj);
            next({...to,replace:true})
      }).catch(e=>{
        store.dispatch('LoginOut')
        Message.warning('会话已过期，请重新刷新页面')
        // next({path:"/login"})
        return false
      })

        // store.dispatch('getRoles',window.name).then(res=>{
        //       setSession('tagStep', from.fullPath);
        //       store.dispatch('global/TAG_ADD', obj);
        //         next({...to,replace:true})
        //   }).catch(e=>{
        //     store.dispatch('LoginOut')
        //     Message.warning('会话已过期，请重新刷新页面')
        //     // next({path:"/login"}) 
        //     return false
        //   })

      }else{
        next()
      }
    }
  }else{
    // 获取不到token
   if(whiteList.indexOf(to.path) !== -1){
    next()

    }else if(window.name &&!(to.path=='/login')){
        // 跳转登录
      // store.dispatch('LoginOut')
      console.log('跳转过来 需要重新登录了')

        store.dispatch('autoLogin',window.name).then(res=>{
          console.log('登录完成跳转到首页')
          next({path:'/echarshtlm'})

        }).catch(e=>{
          store.dispatch('LoginOut')
          Message.warning('会话已过期，请重新刷新页面')
          return false
          // next({path:'/login'})
        })

    //   store.dispatch('getRoles',window.name).then(res=>{
    //     setSession('tagStep', from.fullPath);
    //     store.dispatch('global/TAG_ADD', obj);
    //       next('/')
    // }).catch(e=>{
    //   store.dispatch('LoginOut')
    //   Message.warning('会话已过期，请重新刷新页面')
    //   return false
    //   // next({path:'/login'})
    // })
    }else{
      store.dispatch('LoginOut')
      next({path:'/login'})
    }
  }

})


  