import request from "@/axios/index";
// import qs from 'qs'

// 查询类目不分页
export function getCatConfig(params) {
  return request({
    url: '/cat_config',
    method: "get",
    params
  })
}


// 横坐标
export function getAbscissa(params) {
  return request({
    url: '/field_prop_config',
    method: "get",
    params
  })
}

// 图表筛选
export function chartScreening(params, data) {
  return request({
    url: '/charts',
    method: "post",
    params,
    data,
  })
}

