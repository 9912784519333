import Vue from 'vue'
import VueRouter from 'vue-router'
import baseRoutes from '@/router/methodsrouter.js';
Vue.use(VueRouter)

// 消除路由重复点击报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err);
}
// let sessiondata = getSession('routersess');
let routes = baseRoutes
// Pm.then(res => {
// 	routes = res
// })
// routerdataed().then(res => {
// })
const router = new VueRouter ({
  routes: routes
})



export default router
