
const gutters = {
    getDataEchartsItem: (state) => ({type= 'histogram',id = null } = {}) => {
        if(id === null) return {};
        return state.dataVisualization.dataEcharts[type].find(item => item.id === id);
      },
      getDataEchartsIndex: (state) =>({type= 'histogram'} = {}) => {
        return state.dataVisualization.dataEcharts[type+'Index'];
      }
}

export default gutters