import { setSession } from "@/utils/methods";
import { isArray } from "@/utils/validate";
import router from "@/router";
export default {
  TAG_ADD(state, view) {
    const t = isArray(view);
    if (t) {
      state.tags = view;
    } else {
      if (
        state.tags.some((item, index) => {
          if (item.name == view.name) {
            if (item.path != view.path || item.fullPath != view.fullPath) {
              state.tags.splice(index, 1, {
                ...view,
                title: view.meta.title || "没有名纸",
              });
            }
            return true;
          }
        })
      )
        return;
      state.tags.push(
        Object.assign({}, view, {
          title: view.meta.title || "没有名纸",
        })
      );

      setSession("tags", state.tags);
    }
  },
  TAG_DEL(state, obj) {
    const { handle, routePath } = obj;

    if (handle) {
      const curId = state.tags.findIndex((item) => item.path === routePath);
      switch (handle) {
        case "closeLeft":
          state.tags.splice(1, curId - 1);
          break;
        case "closeRight":
          state.tags.splice(curId + 1);
          break;
        case "closeOther":
          curId === 0
            ? state.tags.splice(curId + 1)
            : (state.tags = [state.tags[0], state.tags[curId]]);

          break;
        case "closeAll":
          state.tags.splice(1);
          if (routePath !== "/home") router.push("/");
      }
    } else {
      if (routePath === "/home") return;
      state.tags = state.tags.filter((item) => item.path !== routePath);
    }

    setSession("tags", state.tags);
  },
  TAG_DELALL(state, view) {
    state.tags = []
  },

  ROUTES_SET(state, router) {
    state.routes = router;
  },
  GET_USERINFO(state, userInfo) {
    state.userInfo = userInfo
    setSession("userInfo", userInfo);
  }
};
