<template>
  <div class="formSelect">
    <!-- 通用数据源 -->
    <van-field readonly clickable name="picker" :value="dataValue" label="数据源" placeholder="点击选择数据源" @click="showPickerClick"></van-field>
    <!-- 普通柱状图----histogram -->
    <van-field v-if="chartsTypeX == 'histogram'" readonly clickable name="picker" :value="histogram.row.label" label="横坐标字段" placeholder="点击选择横坐标字段" @click="showPickerClickSun('histogram', 'row')"></van-field>
    <van-field v-if="chartsTypeX == 'histogram'" readonly clickable name="picker" :value="histogram.col.label" label="纵坐标字段" placeholder="点击选择纵坐标字段" @click="showPickerClickSun('histogram', 'col')"></van-field>
    <!-- 多数据对比柱状图----charDSJZ -->
    <!-- 折线图----lineChart -->
    <!-- 折柱混合----lineHistogram -->
    <!-- 普通饼状图----charbB -->
    <!-- 联动和共享数据集----linkageShare -->
    <!-- 弹窗选择项 -->
    <van-popup v-model="showPicker" position="bottom" :style="{ height: '50%' }">
        <van-picker show-toolbar :columns="columns" @confirm="onConfirmSelect" @cancel="showPickerNO"></van-picker>
    </van-popup>
    <van-popup v-model="showPickerSun" position="bottom" :style="{ height: '50%' }">
        <van-picker show-toolbar :columns="columnsSun" @confirm="columnsSunYes" @cancel="columnsSunNo"></van-picker>
    </van-popup>
  </div>
</template>

<script>
import { getAbscissa } from "@/api/catConfig"
export default {
    name: 'Y-formSelect',
    props: {
        chartsTypeX: {
            type: String,
            default: ""
        },
        chartSetX: {
            type: Object,
            default: {}
        },
        dataSourceOptionList: {
            type: Array,
            default: []
        }
    },
    data () {
        return {
            showPicker: false,
            showPickerSun: false,
            dataValue: '',
            dataId: '',
            columns: [],
            columnsSun: [],
            transitValue: '',
            transitValueSun: '',
            histogram: {
                col: {
                    label: '',
                    vallue: ''
                },
                row: {
                    label: '',
                    vallue: ''
                }
            }
        }
    },
    watch: {
        chartsTypeX: {
            handler(newName) {
                console.log("chartsTypeX", newName)
            },
            immediate: true,
            deep: true
        },
        chartSetX: {
            handler(newName) {
                console.log("chartSetX", newName)
                for (let i = 0; i < this.dataSourceOptionList.length; i ++) {
                    if (newName.datasetId == this.dataSourceOptionList[i].id) {
                        this.dataValue = this.dataSourceOptionList[i].dsName
                        this.dataId = this.dataSourceOptionList[i].id
                        break;
                    }
                }
                if (newName.datasetId && newName.datasetId !== "" && newName.datasetId !== null && newName.datasetId !== undefined) {
                    // if (this.columnsSun.length == 0) {
                        getAbscissa({dsId: newName.datasetId}).then(res => {
                            this.columnsSun = res
                            let chartsData = []
                            for (let i = 0; i < res.length; i ++) {
                                let chartsObj = {
                                    text: res[i].showName,
                                    index: res[i].fname
                                }
                                chartsData.push(chartsObj)
                            }
                            this.columnsSun = chartsData
                        })
                    // }
                }
            },
            immediate: true,
            deep: true
        },
    },
    created () {
        console.log(this.chartsTypeX)
        console.log(this.chartSetX)
        console.log(this.dataSourceOptionList)
        this.initialDataProcessing()
    },
    methods: {
        initialDataProcessing () {
            let chartsData = []
            for (let i = 0; i < this.dataSourceOptionList.length; i ++) {
                let chartsObj = {
                    text: this.dataSourceOptionList[i].dsName,
                    index: this.dataSourceOptionList[i].id
                }
                chartsData.push(chartsObj)
            }
            this.columns = chartsData
        },
        showPickerClick () {
            this.showPicker = true
        },
        onConfirmSelect (data) {
            console.log(data)
            this.dataValue = data.text
            this.dataId = data.index
            getAbscissa({dsId: data.index}).then(res => {
                this.columnsSun = res
                let chartsData = []
                for (let i = 0; i < res.length; i ++) {
                    let chartsObj = {
                        text: res[i].showName,
                        index: res[i].fname
                    }
                    chartsData.push(chartsObj)
                }
                this.columnsSun = chartsData
            })
            this.showPicker = false
        },
        showPickerNO () {
            this.showPicker = false
        },
        showPickerClickSun (fath, sun) {
            this.transitValue = fath
            this.transitValueSun = sun
            this.showPickerSun = true
        },
        columnsSunYes (data) {
            this[this.transitValue][this.transitValueSun].label = data.text
            this[this.transitValue][this.transitValueSun].vallue = data.index
            this.showPickerSun = false
        },
        columnsSunNo () {
            this.showPickerSun = false
        },
    },
    mounted () {
    }
}
</script>
<style scoped lang="less">
.formSelect {}
</style>
