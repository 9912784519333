<template>
  <div class="home">
    <div v-if="pcEquipment" class="homesun">
      <el-container>
        <el-aside style="overflow: hidden; transition: 0.2s; height: 100%"
          v-if="showContent" :width="width" >
          <el-col style="height: 100%">
            <el-menu router ref="menus" unique-opened class="el-menu-vertical-demo"
              text-color="#fff" active-text-color="#409EFF" background-color="#304156"
              :default-active="qihuantabdhspan == '设计' ? datasetId : rightSet"
              :collapse="collapse" :collapse-transition="false"
              style=" overflow: hidden; border: none; display: flex; flex-direction: column; height: 100%;">
              <div :class="logoboxttd" style="width: 100%; height: 60px; overflow: hidden">
                <span v-if="logospandisabled" class="logospan"> BI </span>
              </div>

              <div style="flex: 1; overflow: auto; height: 98%; overflow: auto">
                <div v-show="qihuadisabled" v-for="(team, index) in routerdata">
                  <el-submenu :index="team.id" v-if="team.children && team.children.length && team.menuType == 0">
                    <template slot="title">
                      <svg-icon v-if="team.icon" :icon-class="team.icon" class="el-icon-document" style="color: #fff !important"/>
                      <i class="el-icon-document" v-else></i>
                      <span style="margin-left: 10px"> {{ team.dsName }}</span>
                    </template>
                    <el-menu-item-group>
                      <el-menu-item class="hiddenOverflow hiddenOverflowSecond" v-for="(subTeam, index) in team.children"
                        :key="index" :index="index + ' '" route="/echarshtlm" @click="clickedsa(subTeam)">
                        <el-tooltip class="item-tooltip" effect="dark" :content="subTeam.dsName" placement="top" :open-delay="200">
                          <template v-slot:default>
                            <span>
                              <svg-icon v-if="subTeam.icon" :icon-class="subTeam.icon" class="el-icon-document" style="color: #fff !important"/>
                              <i class="el-icon-document" v-else></i>
                              <span slot="title">{{ subTeam.dsName }}</span>
                            </span>
                          </template>
                        </el-tooltip>
                      </el-menu-item>
                    </el-menu-item-group>
                  </el-submenu>
                  <el-menu-item class="hiddenOverflow" v-else-if="team.menuType == 1 || !team.menuType"
                    :index="team.id" :key="index + ''" route="/echarshtlm" @click="clickedsa(team)">
                    <el-tooltip class="item-tooltip" effect="dark" :content="team.dsName" placement="top" :open-delay="200">
                      <template v-slot:default>
                        <span>
                          <svg-icon v-if="team.icon" :icon-class="team.icon" class="el-icon-document" style="color: #fff !important"/>
                          <i class="el-icon-document" v-else></i>
                          <span slot="title">{{ team.dsName }}</span>
                        </span>
                      </template>
                    </el-tooltip>
                  </el-menu-item>
                </div>

                <!-- 图表设计模块 -->
                <el-menu-item index="/databaseLink" v-show="!qihuadisabled" @click="rightSet = '/databaseLink'">
                  <i class="el-icon-document"></i>
                  <span slot="title">数据库链接</span>
                </el-menu-item>
                <el-menu-item index="/dataSet" v-show="!qihuadisabled" @click="rightSet = '/dataSet'">
                  <i class="el-icon-document"></i>
                  <span slot="title">数据集</span>
                </el-menu-item>
                <el-menu-item index="/chart" v-show="!qihuadisabled" @click="rightSet = '/chart'">
                  <i class="el-icon-document"></i>
                  <span slot="title">图表</span>
                </el-menu-item>
              </div>
            </el-menu>
          </el-col>
        </el-aside>

        <el-container>
          <el-header v-if="showContent">
            <div class="leftContent">
              <div @click="handleCollpse" style="display: flex; justify-content: center; align-items: center;">
                <img src="../../icons/images/caidan.png" alt="" />
                <span v-if="qihuantabdhspan == '设计'" style="margin-left: 20px; font-size: 16px">展 示 页 面</span>
                <span v-if="qihuantabdhspan == '展示'" style="margin-left: 20px; font-size: 16px">设 计 页 面</span>
              </div>
              <div v-if="qihuantabdhspan == '设计'" class="titleName">{{ currantName }}</div>
            </div>
            <div class="caozuo">
              <button @click="qihuantabdh">{{ qihuantabdhspan }}</button>
              <button @click="handleLogout">退出登录</button>
            </div>
          </el-header>
          <el-main v-if="datasetId">
            <keep-alive>
              <router-view v-if="meta && meta.keepAlive" :datasetId="datasetId" :currantName="currantName"></router-view>
            </keep-alive>
            <router-view v-if="!(meta && meta.keepAlive)" :datasetId="datasetId" :currantName="currantName"></router-view>
          </el-main>
        </el-container>
      </el-container>
    </div>

    <div v-if="!pcEquipment" class="moveHome">
      <div class="headerBox">
        <span>BI</span>
      </div>
      <div class="actionBox">
        <van-collapse v-model="activeName" accordion>
          <van-collapse-item v-for="(item, index) in routerdata" :title="item.catName" :key="index" :name="index"
            class="farterBox" size="1rem">
            <div v-for="(iteam, index1) in item.children" :key="index1" class="selectBox" @click="selectBoxClick(iteam)">
              {{ iteam.catName }}
            </div>
          </van-collapse-item>
        </van-collapse>
      </div>
    </div>
  </div>
</template>
<script>
import jsCookie from "js-cookie";
import { mapState, mapActions } from "vuex";
import { getSession, removeSession, setLocal, getLocal } from "@/utils/methods";
import { getdataSetlist, getChartData } from "@/api/dataSet/index.js";

export default {
  name: "Home",
  data() {
    return {
      showContent: !jsCookie.get("isId"),
      activeName: ["0"],
      collapse: false,
      width: "210px",
      defaultOpeneds: ["/echarshtlm", "/databaseLink"],
      logospandisabled: true,
      currantName: "",
      logoboxttd: "logobox",
      qihuantabdhspan: getLocal("qihuantabdhspan") || "设计",
      qihuadisabled: true,
      isTagTrue: false,
      routerdata: [],
      dataEchart: {},
      allDataEchart: [],
      pathRoutar: "",
      pcEquipment: false,
      datasetId: "",
      rightSet: "/databaseLink",
      teamids: "",
      meta: null,
    };
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        this.handleRouteChange();
      },
    },
    datasetId(val) {
      this.currantName = "";
      if (val) {
        this.checkName(this.routerdata);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.handleRouteChange();
    });
  },
  computed: {
    ...mapState({
      tags: (state) => state.global.tags,
    }),
  },
  methods: {
    //路由变化是
    handleRouteChange() {
      const { name, fullPath, hash, meta, params, path, query } = this.$route;
      if (name) {
        let obj = { name, fullPath, hash, meta, params, path, query };
        this.tagsAdd(obj);
      }
      this.meta = meta;
    },
    checkName(arr) {
      for (const item of arr) {
        if (item.id == this.datasetId) {
          this.currantName = item.dsName;
          return item.dsName;
        } else if (item.children.length) {
          this.checkName(item.children);
        }
      }
    },
    ...mapActions({
      tagsAdd: "global/TAG_ADD",
      tagsDel: "global/TAG_DEL",
      tagsDelAll: "global/TAG_DELALL",
    }),

    // 判断PC还是移动
    browserRedirect() {
      this.pcEquipment = this.$pcEquipment;
    },

    refreshDirect() {
      // 刷新模块页面重定位
      let path = this.$route.path;
      this.navConfig = [{ index: "1", path: ["/echarshtlm", "/tableView"] }];
      let thisNav = this.navConfig.find((item) => {
        return item.path.includes(path);
      });
      if (thisNav) this.defaultOpeneds = [thisNav.index];
    },
    // 设计/展示切换方法
    qihuantabdh() {
      if (this.qihuantabdhspan == "设计") {
        this.qihuantabdhspan = "展示";
      } else this.qihuantabdhspan = "设计";
      setLocal("qihuantabdhspan", this.qihuantabdhspan);
      this.getNow();
    },

    getNow() {
      this.tagsDelAll();
      this.qihuadisabled = this.qihuantabdhspan == "展示" ? false : true;
      this.$router.push({
        path: this.qihuantabdhspan == "展示" ? this.rightSet : "/echarshtlm",
      });
      this.refreshDirect();
      this.getLargeCategoryjuoqu();
    },

    /* 现在路由 */
    getLargeCategoryjuoqu() {
      let dataSetId = "";
      let id = this.$store.state.user.ids;
      getdataSetlist(id ? { id } : null).then((res) => {
        this.routerdata = (res.result ? res.result : res) || [];
        // 遍历菜单列表直到找到第一个menuType为1的列表项
        if (this.routerdata.length) {
          for (const menu of this.routerdata) {
            if (menu.menuType == 1) {
              // 如果menuTypew为1直接获取当前的id
              dataSetId = menu.id;
              break;
            } else if (menu.menuType == 0) {
              // 如果menuTypew为0 获取children里面的第0条
              if (menu.children?.length) {
                // 如果有children
                dataSetId = menu.children[0].id;
                break;
              }
            } else if (!menu.menuType) {
              dataSetId = menu.children.length ? menu.children[0].id : menu.id;
              break;
            }
          }
          this.datasetId = getLocal("datasetId") || dataSetId;
          // let currentMenu = res.filter((f) => f.id == this.datasetId)?.[0]?.dsName;
          let obj = { id: this.datasetId };
          this.clickedsa(obj);
        }
      });
    },

    clickedsa(team) {
      setTimeout((_) => {
        this.datasetId = team.id;
        // this.$refs.menus.updateActiveIndex(team.id);
      }, 0);
    },

    // 触发菜单栏放大缩小
    handleCollpse() {
      this.collapse = !this.collapse;
      this.logospandisabled = !this.collapse;
      this.logoboxttd = this.collapse ? "logoboxto" : "logobox";
      this.$nextTick(() => {
        this.width = this.collapse ? "54px" : "210px";
      });
    },

    // 退出登录
    handleLogout() {
      this.$store.dispatch("LoginOut");
      this.$router.replace("/login");
      // 刷新页面清空缓存
      setTimeout(() => {
        window.location.reload();
      }, 100);
    },

    /**移动端 */
    selectBoxClick(iteam) {
      this.$router.push({
        name: "moveCharts",
        params: { id: iteam.id, name: iteam.catName },
      });
    },
  },
  created() {
    this.browserRedirect();
  },
  mounted() {
    // this.qihuantabdh()
    this.getNow();
  },
};
</script>
<style scoped lang="less" scoped>
.leftContent {
  display: flex;
  // flex: 1;
  .titleName {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    font-weight: 600;
    margin-top: 3px;
  }
}
.hiddenOverflow {
  list-style: none;
  display: block;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 30px 0 35px !important;
}
.hiddenOverflowSecond {
  padding-left: 50px !important;
}
@font-size-base: 75;
.home {
  width: 100%;
  height: 100%;
  .homesun {
    height: 100%;
    width: 100%;
  }
}

.logobox {
  background: url("../../assets/logo.jpg");
  background-repeat: no-repeat;
  background-size: 100%;
  text-align: center;
}
.logoboxto {
  background: url("../../assets/logo.jpg");
  background-size: 100%;
  text-align: center;
}
.logospan {
  font-size: 24px;
  color: white;
}
.el-header,
.el-footer {
  background-color: #ffffff;
  color: #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
::-webkit-scrollbar {
  display: none;
}

.el-aside {
  background-color: #304156;
  color: #333;
  text-align: start;
  line-height: 60px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  padding: 10px !important;
  flex-basis: 0px;
}
.el-container {
  height: 100%;
}
body > .el-container {
  margin-bottom: 40px;
}

.caozuo {
  button {
    margin: 0 5px;
    height: 30px;
    width: 100px;
    font-weight: 600;
    background: white;
    border-radius: 4px;
    border: 1px solid rgb(152, 152, 152);
  }
}
/deep/ .el-table .el-table__cell {
  padding: 6px;
}
/deep/ .el-button {
  padding: 6px;
}
.moveHome {
  width: 100%;
  height: 100%;
  // padding-top: 20%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .headerBox {
    width: 10rem;
    height: 1.4rem;
    background: rgb(87, 173, 247);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 0.466667rem;
    font-weight: 600;
  }
}
.selectBox {
  width: 100%;
  border-top: 0.5px solid #d4d4d5;
  padding: 10px 0;
  text-align: center;
  font-size: 0.38rem;
}
/deep/ .el-collapse-item__header {
  justify-content: center;
}
/deep/ .el-collapse-item__arrow {
  position: absolute;
  left: 10px;
  margin: 0;
}
/deep/ .el-collapse-item__content {
  padding: 0;
}
/deep/.van-cell__title {
  font-size: 0.38rem;
  height: 0.7rem;
  display: flex;
  align-items: center;
}
/deep/.van-cell__right-icon {
  height: 0.7rem;
  font-size: 0.38rem;
  display: flex;
  align-items: center;
}
/deep/.van-collapse-item__content {
  padding: 0;
}
</style>
