<template>
  <div class="input">
    <van-field v-model="inputval" :name="inputNamel" :label="inputNamel" :placeholder="inputNamel" @input="input"></van-field>
  </div>
</template>

<script>
export default {
    name: 'Y-input',
    props: {
      inputName: {
        type: String,
        default: "",
      },
      inputValue: {
        type: String,
        default: "",
      },
    },
    watch: {
      inputValue: {
        handler(newName) {
          this.inputval = newName
        },
        immediate: true,
        deep: true
      },
      inputName: {
        handler(newName) {
          this.inputNamel = newName
        },
        immediate: true,
        deep: true
      }
    },
    data () {
        return {
          inputval: '',
          inputNamel: ''
        }
    },
    created () {
      this.inputNamel = this.inputName
    },
    methods: {
      input (val) {
        this.$emit("input", val)
      },
    },
    mounted () {
    }
}
</script>
<style scoped lang="less">
</style>