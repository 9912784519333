import request from "@/axios/index";

// 根据bs的token获取bi对应的token
export function tokenOrToken(data) {
    return request({
      url: '/auth',
      headers: {
        isToken: false
      },
      method: "post",
      data
    });
  }
  // 获取权限所有
  export  function getPermiss(){
    return request({
      url: '/getPermissions',
      method: "post",
    });
  }
