import request from "@/axios/index";
// import qs from 'qs'
// 获取图表配置数据
export function getChartsList(params) {
  return request({
    url: '/chart_config',
    method: "get",
    params
  })
}
// 获取图表内容数据
export function getChartsData(data) {
  return request({
    url: '/charts/charts_data',
    method: "post",
    data
  })
}
// 根据数据集获取图表内容数据
export function getdateBaseChartsData(data, id) {
  return request({
    url: `/charts/charts_data/v2?datasetId=${id}`,
    method: "post",
    data
  })
}


// 获取单图表内容数据
export function getCharData(data) {
  return request({
    url: '/charts/chart_data',
    method: "post",
    data
  })
}
