<template>

    

    <!-- 设计图表右侧得数据修改 -->
    <div class="formBox">
    
        <!-- 普通柱状图 -->
        <el-form v-if="chartsTypespan == 'histogram'" :model="form" label-width="100px" class="demo-dynamic">
            <el-form-item label="数据源">
                <el-select style="width:180px" disabled clearable v-model="form.dataset" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'father')">
                    <el-option v-for="item in dataSourceOption" :key="item.id" :label="item.dsName" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="横坐标字段">
                <el-select style="width:180px" clearable v-model="form.row" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun')">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="纵坐标字段">
                <el-select style="width:180px" clearable v-model="form.col" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun')">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标题">
                <el-input size="mini" style="width:180px" @change="(val) => datasourceChange(val, null, 'sun')"
                    v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button v-if="!deleteDisabled" type="danger" @click="deletelist">删除</el-button>
                <el-button v-if="deleteDisabled" type="primary" icon="el-icon-search"
                    @click="searchClick(form)">搜索</el-button>
            </el-form-item>
        </el-form>
        <!-- 多数据对比柱状图 -->
        <el-form v-if="chartsTypespan == 'charDSJZ'" :model="form" label-width="100px" class="demo-dynamic">
            <el-form-item label="数据源">
                <el-select style="width:180px" disabled clearable v-model="form.dataset" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'father')">
                    <el-option v-for="item in dataSourceOption" :key="item.id" :label="item.dsName" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="横坐标字段">
                <el-select style="width:180px" clearable v-model="form.row" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun')">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="纵坐标字段">
                <el-select style="width:180px" clearable v-model="form.col" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun')">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标题">
                <el-input style="width:180px" size="mini" @change="(val) => datasourceChange(val, null, 'sun')"
                    v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="数据分组">
                <el-select style="width:180px" clearable v-model="form.cat" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun')">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button v-if="!deleteDisabled" type="danger" @click="deletelist">删除</el-button>
                <el-button v-if="deleteDisabled" type="primary" icon="el-icon-search"
                    @click="searchClick(form)">搜索</el-button>
            </el-form-item>
        </el-form>
        <!-- 折线图 -->
        <el-form v-if="chartsTypespan == 'lineChart'" :model="form" label-width="100px" class="demo-dynamic">
            <el-form-item label="数据源">
                <el-select style="width:180px" disabled clearable v-model="form.dataset" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'father')">
                    <el-option v-for="item in dataSourceOption" :key="item.id" :label="item.dsName" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="横坐标字段">
                <el-select style="width:180px" clearable v-model="form.row" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun')">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="纵坐标字段">
                <el-select style="width:180px" clearable v-model="form.col" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun')">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标题">
                <el-input style="width:180px" size="mini" @change="(val) => datasourceChange(val, null, 'sun')"
                    v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button v-if="!deleteDisabled" type="danger" @click="deletelist">删除</el-button>
                <el-button v-if="deleteDisabled" type="primary" icon="el-icon-search"
                    @click="searchClick(form)">搜索</el-button>
            </el-form-item>
        </el-form>
        <!-- 折柱混合 -->
        <el-form v-if="chartsTypespan == 'lineHistogram'" :model="form" label-width="100px" class="demo-dynamic">
            <el-form-item label="数据源">
                <el-select style="width:180px" disabled clearable v-model="form.dataset" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'father')">
                    <el-option v-for="item in dataSourceOption" :key="item.id" :label="item.dsName" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="横坐标字段">
                <el-select style="width:180px" clearable v-model="form.row" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun', chartsTypespan)">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标题">
                <el-input style="width:180px" size="mini" @change="(val) => datasourceChange(val, null, 'sun')"
                    v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="柱图纵坐标">
                <el-select style="width:180px" clearable v-model="form.bars" multiple placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun', chartsTypespan)">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>

            <el-form-item label="折图纵坐标">
                <el-select style="width:180px" clearable v-model="form.lines" multiple placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun', chartsTypespan)">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button v-if="!deleteDisabled" type="danger" @click="deletelist">删除</el-button>
                <el-button v-if="deleteDisabled" type="primary" icon="el-icon-search"
                    @click="searchClick(form)">搜索</el-button>
            </el-form-item>
        </el-form>
        <!-- 普通饼状图 -->
        <el-form v-if="chartsTypespan == 'charbB'" :model="form" label-width="100px" class="demo-dynamic">
            <el-form-item label="数据源">
                <el-select style="width:180px" disabled clearable v-model="form.dataset" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'father')">
                    <el-option v-for="item in dataSourceOption" :key="item.id" :label="item.dsName" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="字段">
                <el-select style="width:180px" clearable v-model="form.row" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun')">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标题">
                <el-input style="width:180px" size="mini" @change="(val) => datasourceChange(val, null, 'sun')"
                    v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="比例">
                <el-select style="width:180px" clearable v-model="form.col" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun')">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button v-if="!deleteDisabled" type="danger" @click="deletelist">删除</el-button>
                <el-button v-if="deleteDisabled" type="primary" icon="el-icon-search"
                    @click="searchClick(form)">搜索</el-button>
            </el-form-item>
        </el-form>
        <!-- 联动和共享数据集 -->
        <el-form v-if="chartsTypespan == 'linkageShare'" :model="form" label-width="100px" class="demo-dynamic">
            <el-form-item label="数据源">
                <el-select style="width:180px" disabled clearable v-model="form.dataset" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'father')">
                    <el-option v-for="item in dataSourceOption" :key="item.id" :label="item.dsName" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="横坐标字段">
                <el-select style="width:180px" clearable v-model="form.row" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun')">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="纵坐标字段">
                <el-select style="width:180px" clearable v-model="form.col" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun')">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标题">
                <el-input style="width:180px" size="mini" @change="(val) => datasourceChange(val, null, 'sun')"
                    v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="数据分组">
                <el-select style="width:180px" clearable v-model="form.cat" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun')">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button v-if="!deleteDisabled" type="danger" @click="deletelist">删除</el-button>
                <el-button v-if="deleteDisabled" type="primary" icon="el-icon-search"
                    @click="searchClick(form)">搜索</el-button>
            </el-form-item>
        </el-form>
        <!-- 多对比图 -->
        <el-form v-if="chartsTypespan == 'contrastDSJZ'" :model="form" label-width="100px" class="demo-dynamic">
            <el-form-item label="数据源">
                <el-select style="width:180px" disabled clearable v-model="form.dataset" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'father')">
                    <el-option v-for="item in dataSourceOption" :key="item.id" :label="item.dsName" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="横坐标字段">
                <el-select style="width:180px" clearable v-model="form.row" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun')">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="纵坐标字段">
                <el-select style="width:180px" clearable v-model="form.col" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun')">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="标题">
                <el-input style="width:180px" size="mini" @change="(val) => datasourceChange(val, null, 'sun')"
                    v-model="form.title"></el-input>
            </el-form-item>
            <el-form-item label="日期">
                <el-select style="width:180px" clearable v-model="form.date" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form, 'sun')">
                    <el-option v-for="item in abscissaOption" :key="item.value" :label="item.showName" :value="item.fname">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="比较类型">
                <el-select style="width:180px" clearable v-model="form.cmpType" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form)">
                    <!-- <el-option
                    v-for="item in dataSourceOption"
                    :key="item.id"
                    :label="item.dsName"
                    :value="item.id"
                >
                </el-option> -->
                    <el-option label="上期" value="0"></el-option>
                    <el-option label="同期" value="1"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="单位">
                <el-select style="width:180px" clearable v-model="form.ut" placeholder="请选择" size="mini"
                    @change="(val) => datasourceChange(val, this.form)">
                    <!-- <el-option
                    v-for="item in dataSourceOption"
                    :key="item.id"
                    :label="item.dsName"
                    :value="item.id"
                >
                </el-option> -->
                    <el-option label="年" value="0"></el-option>
                    <el-option label="季度" value="1"></el-option>
                    <el-option label="月" value="2"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button v-if="!deleteDisabled" type="danger" @click="deletelist">删除</el-button>
                <el-button v-if="deleteDisabled" type="primary" icon="el-icon-search"
                    @click="searchClick(form)">搜索</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { getdataSetlist } from "@/api/dataSet/index.js";

import { getAbscissa } from "@/api/catConfig"
import clone from "clone"
export default {
    name: 'Y-form',
    props: {
        chartsTypeed: {
            type: String,
            default: ''
        },
        dataSourceCondition: {
            type: Object,
            default: null
        },
        // dataSourceOption: {
        //     type: Array,
        //     default: []
        // },
        deleteDisabled: {
            type: Boolean,
            default: false
        },
    },
    watch: {
        chartsTypeed: {

            handler(newVal) {
                this.chartsTypespan = newVal
            },
            immediate: true,
        },
        dataSourceCondition: {
            handler(newVal) {

                if (newVal.dataset != '' && newVal.dataset != null && newVal.dataset != undefined) {
                    getAbscissa({ dsId: newVal.dataset }).then(res => {
                        this.abscissaOption = res
                    })
                }
                if (this.chartsTypespan == 'lineHistogram') {
                    let val = clone(newVal)

                    if (val.bar != undefined) {
                        val.bars = val.bar.map(item => {
                            return item.fname
                        })
                        delete val.bar
                    }

                    if (val.line != undefined) {
                        val.lines = val.line.map(item => {
                            return item.fname
                        })
                        delete val.line
                    }
                    this.form = val
                    return
                }
                this.form = newVal
                console.log(this.form)

            },
            immediate: true,
        }
    },
    created() {
            this.getDataSource()
    },
    data() {
        return {
            // 数据源选项
            dataSourceOption: [],
            // 所有的数据项都放在一个form里 根据不同的 图表判断
            form: {
                "dataset": "",
                "row": "",
                "col": "",
                "cat": "",
                "date": "",
                "cmpType": "",
                "ut": "",
                "bars": [],
                "lines": []
            },

            histogramForm: {
                dataset: '',
                row: '',
                col: '',
            },
            charDSJZForm: {
                dataset: '',
                row: '',
                col: '',
                cat: '',
            },
            contrastDSJZForm: {
                dataset: '',
                row: '',
                col: '',
                date: '',
                cmpType: '',
                ut: ''
            },
            lineChartForm: {
                dataset: '',
                row: '',
                col: '',
            },
            lineHistogramForm: {
                dataset: '',
                row: '',
                bars: [],
                lines: [],
            },
            charbBForm: {
                dataset: '',
                row: '',
                col: '',
            },
            linkageShareForm: {
                dataset: '',
                row: '',
                col: '',
                cat: '',
            },
            chartsTypespan: '',
            abscissaOption: []
        }
    },
    methods: {

        getDataSource() {
            getdataSetlist().then((res) => {
                this.dataSourceOption = res;
            })
        },
        datasourceChange(val, forms, span, type) {
            let form = clone(this.form)
            if (span == 'father') {

                form.dataset = val
                getAbscissa({ dsId: val }).then(res => {
                    console.log(res)
                    this.abscissaOption = res
                })
                this.$emit('dataSelectChange', form)
            } else {
                if (type == 'lineHistogram') {
                    // 折柱图选择了多选，获取label和value
                    let barLabel = []
                    let barValue = []
                    let lineLabel = []
                    let lineValue = []
                    form.bar = []
                    form.line = []
                    //柱状多选
                    console.log(form)
                    for (let i = 0; i < form.bars.length; i++) {
                        this.abscissaOption.find(item => {
                            if (item.fname == form.bars[i]) {
                                barLabel.push(item.showName)
                                barValue.push(item.fname)
                                // 合并在一个循环不就行了？
                                let obj = {
                                    name: item.showName,
                                    fname: item.fname
                                }
                                form?.bar.push(obj)
                            }
                        })
                    }
                    // 线图多选
                    for (let i = 0; i < form.lines.length; i++) {
                        this.abscissaOption.find(item => {
                            if (item.fname == form.lines[i]) {
                                lineLabel.push(item.showName)
                                lineValue.push(item.fname)
                                let obj = {
                                    name: item.showName,
                                    fname: item.fname
                                }
                                form?.line.push(obj)
                            }
                        })
                    }
                    delete form.bars
                    delete form.lines

                }
                this.$emit('dataSelectChange', form)
            }
        },
        deletelist() {
            this.$emit('deletelist')
        },
        searchClick(form) {
            this.$emit("searchClick", form)
        }
    },
    mounted() {
    }
}
</script>
<style scoped>
.formBox {
    width: 100%;
}

el-form {
    width: 100%;
}</style>
