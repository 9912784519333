export default {
  TAG_ADD({ commit }, view) {
    commit("TAG_ADD", view);
  },
  TAG_DEL({ commit }, view) {
    commit("TAG_DEL", view);
  },
  TAG_DELALL({ commit }, view){
    commit("TAG_DELALL", view)
  },
  ROUTES_SET({ commit }, router) {
    function a(r) {
      return r.map((item) => {
        item.meta && (item.title = item.meta?.title);
        if (item.meta) {
          item.title = item.meta.title;
          item.icon = item.meta.icon;
        }
        if (item.children) {
          a(item.children);
        }
        return item;
      });
    }
    let v = a(router);
    commit("ROUTES_SET", v);
  },
};