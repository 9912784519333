// import { getToken, setToken, removeToken } from '@/utils/auth'
import { loginfunction } from "@/api/login.js";
import jsCookie from "js-cookie";
import state from "../global/state";
import * as db from '@/utils/methods'
import { tokenOrToken,getPermiss } from "@/api/bisystem/bisystem"

const user = {
  state: {
    token: jsCookie.get("token") || '',
    userDetails: jsCookie.get("userDetails") ? JSON.parse(jsCookie.get("userDetails")) : '',
    permissions:  [],
    ids:jsCookie.get("isId") || ''
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_DETAILS: (state, userDetails) => {
      state.userDetails = userDetails
    },
    SET_PERMISSION: (state, permiss) => {
      state.permissions = permiss
    },
    SET_ISID:(state,isId)=>{
      state.ids = isId
    }
  },

  actions: {
    LoginOut({ commit }) {
      // alert()
      commit('SET_TOKEN', '')
      commit('SET_DETAILS', '')
      commit('SET_ISID','')
      commit('SET_PERMISSION',[])
      jsCookie.remove("token");
      jsCookie.remove("isId");
      jsCookie.remove("userDetails");
      jsCookie.remove("stringDevise");
      jsCookie.remove("userName");

      db.removeLocal("qihuantabdhspan");
      db.removeLocal("teamID");
      db.removeLocal("datasetId");
      db.removeLocal("currentMenu");
      db.removeLocal("catName");
      db.removeLocal('SET_PERMISSION')
    },
      //登录逻辑处理
      Login({ commit }, formList) {
          let data = {
              password: "",
              username: "",
          };
          data.username = formList.name;
          data.password = formList.pass;
          // 登录接口

          return new Promise((resolve, reject) => {
              loginfunction(data).then((res) => {
                  if (res.token) {
                      commit('SET_TOKEN', res.token)
                      commit('SET_DETAILS', res.userDetails)

                      jsCookie.set("token", res.token);

                      jsCookie.set("userDetails", JSON.stringify(res.userDetails));
                      resolve(res)
                
                  } else {
                      reject('error')
                  }
                  // 登录接口获取的权限 只有从login页面登录才会走这里 目前和登录的token一个层级返回 没有的话就是最高权限
                  // let permiss =res?.permissions ? res.permissions : ['*:*:*']
                  //     permiss.push('showContent')
                  // commit('SET_PERMISSION', permiss)
                  // db.setLocal('SET_PERMISSION',permiss)

              }).catch(err => {   reject(err) });
          })
      },

// 自动登录的逻辑 
autoLogin({commit,dispatch},name){
  console.log('即将执行自动登录')
  
      // 但凡是走了自动登录 之前的所有登录一律作废 清空tokne和标识重新获取
      dispatch('LoginOut')
      // 判定当前的name是对象（风神嵌入）还是字符串（bs跳转）
      let dataeds = null
      name = ((str) => {
          try {
              return JSON.parse(str);
          } catch (e) {
              return str;
          }
      })(name)         
        dataeds = name?.id? 
      ( ()=>{
      // 风神嵌入传来 不允许看其他内容
      commit('SET_ISID',name.id)
        return  {
          sysCode: "bs",
          token: name.token,
          bsType:0
      } 

  })() : (()=>{
      // bs 外部跳转来的
      return {
          sysCode: "bs",
          token: name,
          bsType:1
      }
  })()
  return new Promise((resolve,roject)=>{
      tokenOrToken(dataeds).then(res => {
          if(res){
              commit('SET_TOKEN',  res.token)
              jsCookie.set("token", res.token);
              jsCookie.set("bsToken", name); // 传入一个bs的标识 用来判定是否自动登录
              // commit('SET_PERMISSION', permiss)
              //  db.setLocal('SET_PERMISSION',permiss)

          resolve(res)
          }else{
              roject(res)
          }
      }).catch(err=>{roject(err)})
  })
  
},

getPermission({commit,dispatch}){
  let permiss = []
  return new Promise((resolve,roject)=>{
      getPermiss().then(res=>{
          permiss = res.permissions
          permiss.map(it=>{
              if (it){
                it = it.replace( /\]$/,'')
                it = it.replace(/(['"])/g,'')
              }
              // console.log(it)
          })
          // console.log(permiss.includes('biIndex.view'))
          // if(!jsCookie.get("isId")){
          //     permiss.push('showContent')
          // }
          commit('SET_PERMISSION', permiss)
          db.setLocal('SET_PERMISSION',permiss)
          resolve(res.permissions)

      }).catch(err=>roject(err))
  })
},


  
  }
}

export default user