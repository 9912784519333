<template>
  <div class="selectInout">
    <van-field v-model="inputval" :name="inputName" :label="inputName" :placeholder="'点击选择' + inputName" readonly @click="showPickerClick"></van-field>
    <van-popup v-model="showPicker" position="bottom" :style="{ height: '50%' }">
        <div class="buttondiv">
            <span @click="cancel">取消</span>
            <span @click="confirm">确定</span>
        </div>
        <van-checkbox-group v-model="result">
            <van-cell-group>
                <van-cell
                    v-for="(item, index) in columnsList"
                    clickable
                    :key="index"
                    :title="item.name"
                    @click="toggle(index)"
                >
                    <template #right-icon>
                        <van-checkbox :name="item.fname" ref="checkboxes"></van-checkbox>
                    </template>
                </van-cell>
            </van-cell-group>
        </van-checkbox-group>
      <!-- <van-picker show-toolbar :default-index="defaultIndex" :columns="columnsList" @confirm="onConfirmSelect" @cancel="showPickerNO"></van-picker> -->
    </van-popup>
  </div>
</template>

<script>
import clone from "clone"
export default {
    name: 'Y-selectsInout',
    props: {
      inputName: {
        type: String,
        default: "",
      },
      inputValue: {
        type: Array,
        default: [],
      },
      columns: {
        type: Array,
        default: [],
      }
    },
    watch: {
      inputValue: {
        handler(newName) {
            let data = []
            for ( let i = 0; i < newName.length; i ++) {
                data.push(newName[i].name)
            }
            this.inputval = data.join(",")
        //   this.inputval = newName
        },
        immediate: true,
        deep: true
      },
      columns: {
        handler(newName) {
            let arr = []
            for (let i = 0; i < newName.length; i ++) {
                let data = {
                    name: newName[i].text,
                    fname: newName[i].value
                }
                arr.push(data)
            }
            this.columnsList = arr
        },
        immediate: true,
        deep: true
      }
    },
    data () {
        return {
          inputval: "",
          showPicker: false,
          columnsList: [],
          defaultIndex: '',
          result: [],
        }
    },
    created () {
    },
    methods: {
        toggle (index) {
            this.$refs.checkboxes[index].toggle();
        },
        showPickerClick (val) {
            this.result = []
            // this.result = this.inputValue
            for (let i = 0; i < this.inputValue.length; i ++) {
                this.result.push(this.inputValue[i].fname)
            }
            this.showPicker = true
        },
        cancel () {
            this.showPicker = false
        },
        confirm () {
            let data = []
            this.inputval = this.result.join(",")
            for (let i = 0; i < this.result.length; i ++) {
                for (let j = 0; j < this.columnsList.length; j ++) {
                    if (this.result[i] === this.columnsList[j].fname) {
                        data.push(this.columnsList[j])
                        break;
                    }
                }
            }
            this.$emit("confirm", data)
            this.showPicker = false
        },
        onConfirmSelect (val) {
            this.showPicker = false
            this.$emit("onConfirmSelect", val)
        },
        showPickerNO () {
            this.showPicker = false
        },
    },
    mounted () {
    }
}
</script>
<style scoped lang="less">
.buttondiv {
    width: 100%;
    padding: .133333rem;
    font-size: .48rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
</style>