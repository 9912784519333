export default {

    moduleType: {
        0: '输入框',
        1: "下拉框",
        2: '时间选择框',
        3: '时间范围选择框'
    },
    filterTypeMap: {
         0: "模糊", 
         1: "等值", 
         2: "范围", 
         3: "比较", 
         4: "数据字典"
        },
    ftypeMap: { 
        0: "varchar", 
        1: "number", 
        2: "date", 
        3: "decimal",
         4: "text" 
        },
        menuTypeDist:{
            0:"目录",
            1:"菜单",
            2:"从表",
          'default':"/"
          },
          addType:{
            0:"sql转义",
            undefined:'sql转义',
            1:"手动虚拟"
          }
}