import request from "@/axios/index";
// import qs from 'qs'
// 查询流程定义
export function loginfunction(data) {
  return request({
    url: '/login',
    method: "post",
    headers: {
      isToken: false
    },
    data
  })
}